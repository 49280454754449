'use strict';

angular
  .module('FSS')
  .factory('PublicService', PublicService);

PublicService.$inject = ['$http', '$rootScope', '$timeout', '$q'];

function PublicService($http, $rootScope, $timeout, $q) {

  var service = {};

  // INIT SERVICE METHODS
  service.submitCallMeForm  = submitCallMeForm;

  // INIT CALLME FORM DATA
  service.callme_form = {
    email: null,
    contact_name: null,
    contact_number: null
  }

  // RETURN AN OBJECT
  return service;


  /*
   * SUBMIT CALL ME FORM
   *
   * @param obj data
   * @return callback
   */
  function submitCallMeForm(data, callback) {

  }

} // END PublicService()
'use strict';

// SOCKET IO
angular.module('FSS').factory('socket', ['$rootScope','$window', function($rootScope, $window) {
    // if($window.location.pathname.substring(0, 3) == "/q/") {
    //     return false;
    // }

    var socket = {};
    var token;

    // METHODS
    var setToken = (_socket = null) => {
        if (($rootScope.globals == null || !$rootScope.globals || angular.equals($rootScope.globals, {}))) {
            try {
                $rootScope.globals = JSON.parse($window.localStorage.getItem('globals'));
            } catch(ex) {
                console.log('>> error while setting up globals: ', ex);
                $rootScope.globals = {};
            }
        }

        if($rootScope.globals && $rootScope.globals.currentUser && $rootScope.globals.currentUser.token) {
            token = $rootScope.globals.currentUser.token;
        }
        setQueryToken(token, _socket);
    }
    var setQueryToken = (token, _socket = null) => {
        if (token && _socket && _socket.io && _socket.io.opts && _socket.io.opts.query) {
            _socket.io.opts.query.token = token;
        }
    }
    var subscribeEvents = (socket) => {
        socket.on('connect', function(con) {
            $rootScope.$apply(()=>{
                // console.log("ke go rekonektirame :", socket);
                $rootScope.socketConnected = true;
                $rootScope.socketStatus = "Connected to server";
                $rootScope.$emit('socketconnected:emit', socket);
            })
            //$rootScope.log("I am inside socket! and connecting with ", token);
            // socket.emit('authentication', {
            //     token: token
            // });
            // socket.on('authenticated', function() {
            //     // use the socket as usual
            //     $rootScope.log('IO Socket: User is authenticated');
            // });

        });
        socket.on('connect_error', (error) => {
            setToken(socket);
           $rootScope.$apply(()=>{
                $rootScope.socketConnected = false;
                $rootScope.socketStatus = "Error while connecting to server:" + error;
           });

        });
        socket.on('connect_timeout', (timeout) => {
            setToken(socket);
            $rootScope.$apply(()=>{
                $rootScope.socketConnected = false;
                $rootScope.socketStatus = "Connection timeout";
            });
        });
        socket.on('error', (error) => {
            setToken(socket);
            $rootScope.$apply(()=>{
                $rootScope.socketConnected = false;
                $rootScope.socketStatus = "Socket error:" + error;
                console.log("error in socket will try to reconnect");
                if($rootScope.globals && $rootScope.globals.currentUser && $rootScope.globals.currentUser.token) {
                    token = $rootScope.globals.currentUser.token;
                }


                if (error == "Authentication error"){
                    $rootScope.socketStatus += " will try to reconnect";
                }
            })


        });
        socket.on('disconnect', (reason) => {
            setToken(socket);
            $rootScope.$apply(()=>{
                $rootScope.socketConnected = false;
                $rootScope.socketStatus = "Disconnected " + reason;
            })
            if (reason === 'io server disconnect') {
                console.log("Disconnected by server");
                // the disconnection was initiated by the server, you need to reconnect manually
                if($rootScope.globals && $rootScope.globals.currentUser && $rootScope.globals.currentUser.token) {
                    token = $rootScope.globals.currentUser.token;
                }
                //   socket.connect();
            }
            // else the socket will automatically try to reconnect
        });
        socket.on('reconnect', (attemptNumber) => {
            setToken(socket);
            $rootScope.$apply(()=>{
                $rootScope.socketConnected = false;
                $rootScope.socketStatus = "Trying to reconnect to server attempt " + attemptNumber;
            })
            // ...
        });
        socket.on('reconnect_attempt', (attemptNumber) => {
            setToken(socket);
            $rootScope.$apply(()=>{
                $rootScope.socketConnected = false;
                $rootScope.socketStatus = "Attempt to reconnect to server " + attemptNumber;
            })
        });
        socket.on('reconnecting', (attemptNumber) => {
            setToken(socket);
            $rootScope.$apply(()=>{
                $rootScope.socketConnected = false;
                $rootScope.socketStatus = "Reconnect to server in progress " + attemptNumber;
            })

        });
        socket.on('reconnect_error', (error) => {
            setToken(socket);
            $rootScope.$apply(()=>{
                $rootScope.socketConnected = false;
                $rootScope.socketStatus = "Error while trying to reconnect to server:" + error.message;
            })
        });
        socket.on('reconnect_failed', () => {
            setToken(socket);
            $rootScope.$apply(()=>{
                $rootScope.socketConnected = false;
                $rootScope.socketStatus = "Failed while trying to reconnect to server";
            })
        });

    }
    // ./METHODS

    setToken();
    if(token) {
        //Creating connection with server
        socket = io.connect({query: {token: token}});
        subscribeEvents(socket);
    }
    return socket;

}]);

// PubSub SERVICE
angular.module('FSS').factory('PubSub',['socket','$rootScope', function(socket, $rootScope) {
    var container = [];
    var m = {
        // socketMessage: (msg, modelId) => {
        //     console.log("socket:", socket);
        //     var name = '/user_project_' + modelId + '/' + modelId + '/PUT';
        //     // socket.io.to(name).emit('nice game', "let's play a game");
        //     socket.emit('writing', "let's play a game");
        // },
        subscribe: function(options, callback) {
            // console.log("Subscribing options:", options);
            if (options) {
                var collectionName = options.collectionName;
                var modelId = options.modelId;
                var method = options.method;
                if (method === 'POST') {
                    var name = '/' + collectionName + '/' + method;
                    if (container.indexOf(name) < 0){
                        socket.on(name, callback);
                    } else {
                        return;
                    }
                } else {
                    var name = '/' + collectionName + '/' + modelId + '/' + method;
                    if (container.indexOf(name) < 0){
                        // console.log("Will subscribe to ", name);
                        socket.on(name, callback);
                    } else {
                        return;
                    }
                    // socket.on(name, callback);
                }
                //Push the container..
                // console.log("Will subscribe to :", name);
                this.pushContainer(name);
            } else {
                throw new Error('Pubsub Option must be an object');
            }
        }, //end subscribe
        companyChannelSubscribe: () => {
            m.subscribe(
                {
                    collectionName: 'CompanyInfo',
                    modelId:0,
                    method: 'PUT'
                }, (result) => {

                    console.log("We have received from pubsub on company channel!", result);
                    if (result.hasOwnProperty("channel")){
                        console.log("Join to channel message received");
                        $rootScope.$emit('joinchannel:emit', result); // $rootScope.$on
                    } else {
                        if($rootScope.pubsubInfo && Array.isArray($rootScope.pubsubInfo)) {
                            $rootScope.pubsubInfo.unshift(result);
                        }
                        $rootScope.addNotification(result);
                        $rootScope.$apply(() => { });
                    }

                })
        },
        reconnect: (callback) => {

            var currentToken = null;
            var newToken = null;

            if($rootScope.globals && $rootScope.globals.currentUser && $rootScope.globals.currentUser.token) {
                newToken = $rootScope.globals.currentUser.token;
            }

            if (socket.connected){
                currentToken = socket.io.opts.query.token;
            }

            if (currentToken != newToken || (!currentToken && !newToken)){
                if (socket && socket.connect){
                    socket.connect();
                }
                callback();
            } else {
                callback();
            }
        },
        disconnect: () => {
            if(socket && socket.disconnect) {
                socket.disconnect(true);
                if(socket.destroy) {
                    socket.destroy();
                }
            }
        },
        pushContainer: function(subscriptionName) {
            container.push(subscriptionName);
        },
        showSubscription: () => {
            return container;
        },
        //Unsubscribe all containers..
        unSubscribeAll: function() {
            for (var i = 0; i < container.length; i++) {
                socket.removeAllListeners(container[i]);
            }
            //Now reset the container..
            container = [];
        },
        // reInitSocket: function() {
        //     console.log('>>> REINIT IN PUBSUB CALLED ');
        //     return socket.reInit();
        // }

    };
    return m;
}]);

// AUTH SERVICE
angular.module('FSS').factory('AuthService', AuthService);
AuthService.$inject = ['$http', '$rootScope', '$q', '$window', 'PubSub', '$sce', '$cookies', 'ConfigService', '$timeout'];
function AuthService($http, $rootScope, $q, $window, PubSub, $sce, $cookies, ConfigService, $timeout) {
    var self = this;
    var service = {};

    service.readMessages = {};
    service.unreadMessages = {};
    service.chatMessages = {};
    service.activeChannels = {};
    service.visibleChats = {};
    service.activeChats = [];
    $rootScope.pubsubInfo = [];
    service.error403 = null;
    service.http_requests = [];
    service.myProfile = {};
    service.currentUser = "";

    service.__login = __login;
    service.__register = __register;
    // service.__loginGoogle = __loginGoogle;
    service.__initializeGlobals = __initializeGlobals;
    service.__initializeGlobalsPromise = __initializeGlobalsPromise;
    service.__updateMyProfile = __updateMyProfile;
    service.__reSendConfirmationEmail = __reSendConfirmationEmail;
    service.__getNotifications = __getNotifications ;
    service.__readNotification = __readNotification;
    service.__readMessage = __readMessage;
    service.__readAllNotification = __readAllNotification;
    service.__getCompanyFromAbn = __getCompanyFromAbn;
    service.__logevent  = __logevent;
    service.__getMyDashboardWidgets = __getMyDashboardWidgets;
    service.__getMyAvailableWidgets = __getMyAvailableWidgets;
    service.__submitFeedback = __submitFeedback;

    service.passwordResetLink = passwordResetLink;
    service.acceptPasswordReset = acceptPasswordReset;
    service.Logout = Logout;
    service.SetCredentials = SetCredentials;
    service.ClearCredentials = ClearCredentials;
    service.ClearFrontCredentials = ClearFrontCredentials;
    service.getLoggedUser = getLoggedUser;
    service.UserExists = UserExists;
    service.__userExistsPromise = __userExistsPromise;
    service.__checkIfAbnAlreadyInUse = __checkIfAbnAlreadyInUse;
    service.__getMyUserPayload = __getMyUserPayload;
    service.__declineInvitation = __declineInvitation;
    service.__acceptInvitation = __acceptInvitation;
    service.__validateAccount = __validateAccount;
    // service.__joinChannels = __joinChannels;
    service.__joinChannel = __joinChannel;
    service.__getProjectMessages = __getProjectMessages;
    service.__getProjectMessagesPromise = __getProjectMessagesPromise;
    service.__sendMessage = __sendMessage
    service.DynamicItems = DynamicItems;
    // service.__socketMessageSend = __socketMessageSend;
    service.__isEmailFormatValid = __isEmailFormatValid;

    service.__renewCsrfIfRequired = (callback) => {
        var csrf = $cookies.get('_csrf');
        var xsrf = $cookies.get('XSRF-TOKEN');


        if (csrf && xsrf){
            // console.log("We already have csrf, xsrf:", csrf, xsrf);
            return callback();
        }
        $http.get('/api/test')
        .then((response) => {
            // console.log("We should have renewed csrf, xsrf:", response);
            return callback();
        })
        .catch((err) => {
            return callback()
        })

    };

    service.loadGapi = function(){
        var deferred = $q.defer();
        // gapi.load('auth2', function(){
        //   var auth2 = gapi.auth2.init({client_id: '415410027250-32havk00d69n1au2n311c2p7aj3v8244.apps.googleusercontent.com' , scope: 'email profile openid', prompt:'select_account'});
        //   //normally I'd just pass resolve and reject, but page keeps crashing (probably gapi bug)
        //   auth2.then(function(){
        //     deferred.resolve();
        //   });
        //   addAuth2Functions(auth2);
        // });
        try {
            gapi.load('auth2', {
                callback: function() {
                    // Handle gapi.client initialization.
                    // var auth2 = gapi.auth2.init({client_id: '415410027250-32havk00d69n1au2n311c2p7aj3v8244.apps.googleusercontent.com' , scope: 'email profile openid', prompt:'select_account'});
                    // var auth2 = gapi.auth2.init({client_id: '663590159786-4s66h149n13k48j3kndjjjtk2a9m39j2.apps.googleusercontent.com' , scope: 'email profile openid', prompt:'select_account'});
                    var auth2 = gapi.auth2.init({client_id: ConfigService.__getEnvSettings().gclient_id , scope: 'email profile openid', prompt:'select_account'});

                    //normally I'd just pass resolve and reject, but page keeps crashing (probably gapi bug)
                    auth2.then(function(){
                        deferred.resolve();
                    })
                    .catch(err => {
                        deferred.reject(err);
                    });
                    addAuth2Functions(auth2);
                },
                onerror: function(err) {
                    // Handle loading error.
                    console.log('>> error from gapi.load(): ', err);
                    deferred.reject(err);
                },
                timeout: 5000, // 5 seconds.
                ontimeout: function() {
                    // Handle timeout.
                    console.log('>> cannot load gapi within 5 seconds');
                    deferred.reject('cannot load gapi within 5 seconds');
                }
            });
        } catch(ex) {
            deferred.reject(ex);
        }
        return deferred.promise;
    };

    var DynamicItems = function (projectId) {
        /**
         * @type {!Object<?Array>} Data pages, keyed by page number (0-index).
         */
        this.loadedPages = {};

        /** @type {number} Total number of items. */
        this.numItems = 0;
        this.currentProject = projectId;
        /** @const {number} Number of items to fetch per request. */
        this.PAGE_SIZE = 10;
        this.currentPageNumber = 0;
        this.totalItems = 0;
        this.loadedItems = 0;

        this.loadingItems = 0;
        this.fetchingItems = false;
        this.readMessages = {};
        this.pendingReload = 0;

        this.messages = [];
        this.fetchNumItems_();
        // console.log("object:", this);

    };

    DynamicItems.prototype.addReadMessages = function (messages, user) {
        var _self = this;
        if (_self.readMessages) {
            var rm = _self.readMessages
            angular.forEach(messages, function (msg) {
                if (!rm[msg]) {
                    rm[msg] = [user]
                } else {
                    rm[msg].push(user)
                }
            })
        }
    }

    DynamicItems.prototype.markMessageAsRead = function (msg, users) {
        if (!msg || !users) return null;

        var _self = this;
        var ret = null;

        angular.forEach(users, function (user) {

            var isRead = msg.notificationRead.findIndex(x => x.UserNotified.UserId == user.UserNotified.UserId);
            if (isRead < 0) {
                if ( service.unreadMessages[_self.currentProject] > 0){
                    // service.unreadMessages[_self.currentProject] -= 1;
                    $rootScope.$emit("increment_" + _self.currentProject + ":emit", {projectId: _self.currentProject , increment: -1})
                }
                console.log("Comparing: ", JSON.stringify(user), JSON.stringify(msg.notificationRead));
                $timeout(() => {
                    var stillUnread = msg.notificationRead.findIndex(x => x.UserNotified.UserId == user.UserNotified.UserId);
                    if (stillUnread < 0){
                        msg.notificationRead.push(user)
                    }
                });

                ret = true;
            }
        })
        return ret;

    }

    DynamicItems.prototype.addItemAtTop = function (object) {
        var _self = this;

        _self.messages.unshift(object);
        // _self.totalItems++;
        // _self.loadedItems++;
    }

    DynamicItems.prototype.incrementTotalItems = function() {
        var _self = this;
        _self.totalItems++;
        _self.loadedItems++;
        _self.loadingItems++;
    }

    // Required.
    DynamicItems.prototype.getItemAtIndex = function (index) {
        var _self = this;
        // if (!_self.hold) {
            if (index > _self.loadedItems) {
                _self.fetchMoreItems_(index);
                return null;
            }
        // }

        for(var i = index; i < (index + _self.PAGE_SIZE); i++ ){
            if(_self.readMessages.length <= 0){
                break;
            }
            var _msg = _self.messages[i];
            if (_msg && _self.readMessages[_msg.id] && _self.markMessageAsRead(_msg, _self.readMessages[_msg.id])) {
                delete _self.readMessages[_msg.id];
            }
        }

        return _self.messages[index];
    };

    // Required.
    DynamicItems.prototype.getLength = function () {
        var _self = this;

        var length  =   ((_self.totalItems - _self.loadedItems) - _self.PAGE_SIZE < 0 ? _self.totalItems :_self.loadedItems + _self.PAGE_SIZE);
        return length;
    };

    DynamicItems.prototype.fetchMoreItems_ = function (index) {
        var _self = this;

        _self.fetchingItems = true;

        if (_self.loadingItems < index){
            // console.log("Index:", index, " loadingItems:", _self.loadingItems, " uslov:", _self.loadingItems < index);
            _self.loadingItems += _self.PAGE_SIZE;
            service.__getProjectMessagesPromise({
                    projectId: _self.currentProject,
                    offset: index-1,
                    pagesize: _self.PAGE_SIZE
                })
                .then((res) => {
                    // console.log("fetchPage:", res.data, " index:", index);
                    $timeout(() => {
                        _self.fetchingItems = false;
                        _self.messages = _self.messages.concat(res.data.rows);
                        _self.loadedItems = _self.loadingItems;

                    })
                })
                .catch((err) => {
                    _self.fetchingItems = false;
                    console.log("Error while loading messages:", err);
                })
        }
    };

    DynamicItems.prototype.fetchNumItems_ = function () {
        // For demo purposes, we simulate loading the item count with a timed
        // promise. In real code, this function would likely contain an
        // $http request.
        // angular.bind(this, function() {
        var _self = this;
        // console.log("fetchNumItems_ called:", _self.currentProject);
        service.__getProjectMessages({
            projectId: _self.currentProject,
            onlycount: 1
        }, (res) => {
            // console.log("fetchNum:", res);
            _self.totalItems = res.count;
            _self.numItems = 0
            _self.loadedItems = 0;
            _self.readMessages = {};
            // _self.loadedPages[0] = res.rows;
        });
        // });
    };

    $rootScope.$on('socketconnected:emit', (event) => {
        if ($rootScope.globals && $rootScope.globals.currentUser && $rootScope.globals.currentUser.type &&
            $rootScope.globals.currentUser.type != 'undefined' && $rootScope.globals.currentUser.confirmed ){
            try {
                    service.__getNotifications((callback) => {
                        $rootScope.pubsubInfo = callback;
                    })
                    PubSub.companyChannelSubscribe();
                    // service.__joinChannels();
            } catch (ex){
                console.log("Error in watcher:", ex);
            }
        }
    });
    $rootScope.$on('joinchannel:emit', (event, data) => {
        if ($rootScope.globals && $rootScope.globals.currentUser && $rootScope.globals.currentUser.type &&
            $rootScope.globals.currentUser.type != 'undefined' && $rootScope.globals.currentUser.confirmed ){
            try {

                service.__joinChannel(data);
                    // console.log( "Active chats:", service.activeChannels);
                    // $rootScope.$apply(()=>{});

            } catch (ex){
                console.log("Error in watcher:", ex);
            }
        }
    });
    $rootScope.$on('chatmessage:emit', function (event, data) {
        // console.log("event i data:", event, data);

        if (!data.projectId) {
            $rootScope.addNotification({
                type: 'error',
                message: 'There is no active project'
            });
            return;
        }
        // console.log("we have message that is not received in project ", data.projectId);

        if (service.chatMessages[data.projectId] && typeof (service.chatMessages[data.projectId].addItemAtTop) == 'function') {
            $timeout(() => {
                if (!service.unreadMessages[data.projectId]){
                    service.unreadMessages[data.projectId] = 0;
                }

                $rootScope.$emit("increment_" + data.projectId + ":emit", {projectId: data.projectId , increment: 1})
                // console.log("Adding message at top to project:", data.projectId);
                service.unreadMessages[data.projectId] +=1;
                service.chatMessages[data.projectId].incrementTotalItems();
                service.chatMessages[data.projectId].addItemAtTop({
                    id: data.id,
                    userid: data.userid,
                    username: data.username,
                    message: data.message,
                    createdAt: data.createdAt,
                    projectName: data.projectName,
                    projectId: data.projectId,
                    url: data.url,
                    notificationRead: data.notificationRead || []
                });
            })
        }

    }); // $rootScope.$on

    $rootScope.$on('chatmessage_read:emit', function (event, data) {
        // console.log("event i data:", event, data);
        if (!data.projectId) {
            $rootScope.addNotification({
                type: 'error',
                message: 'There is no active project'
            });
            return;
        }

        if (!data.messageid) {
            $rootScope.log("error", "We  have received invalid messageid array to mark as read");
            return;
        }
        if (service.chatMessages[data.projectId] && typeof (service.chatMessages[data.projectId].addReadMessages) == 'function') {
            service.chatMessages[data.projectId].addReadMessages(data.messageid, {
                username: data.username,
                image_url: data.image_url,
                UserNotified: {
                    UserId: data.userid,
                    createdAt: data.createdAt
                }
            })
        }

    }); // $rootScope.$on
    return service;


    // function __socketMessageSend(msg, modelId) {
    //     PubSub.socketMessage(msg, modelId);
    // }
    function addAuth2Functions(auth2){
      service.signIn = function() {
        var deferred = $q.defer();
        auth2.signIn().then(deferred.resolve, deferred.reject);
        return deferred.promise;
      };

      service.isSignedIn = function(){
        return auth2.isSignedIn.get();
      }

      service.signOut = function(){
        var deferred = $q.defer();
        if (service.isSignedIn()){
            auth2.signOut().then(deferred.resolve, deferred.reject);
        } else {
            deferred.resolve();
        }
        return deferred.promise;
      };
    }

    function __initializeGlobals() {
        if ( !$window.localStorage.getItem('globals') || $window.localStorage.getItem('globals').length <= 0){
            console.log("Initializing globals will clear credentials");
            service.ClearFrontCredentials().then(() => { });

            return;
        }

        try {
            $rootScope.globals = JSON.parse($window.localStorage.getItem('globals'));
            if($rootScope.globals && $rootScope.globals.currentUser && $rootScope.globals.currentUser.username) {
                service.currentUser = $rootScope.globals.currentUser.username;
                service.myProfile = $rootScope.globals.currentUser;
                // $http.defaults.headers.common['Authorization'] = $rootScope.globals.currentUser.token;
                // $cookies.put('fb_token', $rootScope.globals.currentUser.token, {path: '/'});
            }
        } catch(ex) {
            console.log('>> error while setting up globals: ', ex);
            // service.ClearCredentials();
            service.ClearFrontCredentials().then(() => { });
        }

    }

    function __initializeGlobalsPromise() {
        var deferred = $q.defer();

        if ( !$window.localStorage.getItem('globals') || $window.localStorage.getItem('globals').length <= 0){
            console.log('>> DEBUG FROM INIT GLOBALS: !localstorage');
            // service.ClearCredentials();
            service.ClearFrontCredentials().then(() => { });
            deferred.reject();
            return deferred.promise;
        }
        try {
            $rootScope.globals = JSON.parse($window.localStorage.getItem('globals'));
            if($rootScope.globals && $rootScope.globals.currentUser && $rootScope.globals.currentUser.username) {
                service.currentUser = $rootScope.globals.currentUser.username;
                service.myProfile = $rootScope.globals.currentUser;
                // $http.defaults.headers.common['Authorization'] = $rootScope.globals.currentUser.token;
                // $cookies.put('fb_token', $rootScope.globals.currentUser.token, {path: '/'});
                deferred.resolve();
            } else {
                //we have to check why do we had this resolve here ???
                // deferred.resolve();
                console.log('>> DEBUG FROM INIT GLOBALS: try->if: ', JSON.stringify($rootScope.globals));
                // service.ClearCredentials();
                service.ClearFrontCredentials().then(() => { });

                deferred.reject();
            }
        } catch(ex) {
            console.log('>> DEBUG FROM INIT GLOBALS: try->catch: ', ex);
            // service.ClearCredentials();
            service.ClearFrontCredentials().then(() => { });

            deferred.reject();
        }

        return deferred.promise;
    }

    function getLoggedUser() {
        try {
            return $rootScope.globals.currentUser.username;
        } catch (err) {
            return "";
        }
    }

    function acceptPasswordReset(data,  callback) {
        $http.post('/api/user/reset',data)
            .then(function(response) {
                // $rootScope.log('===================================== AUTH SERVICE ===============================');
                $rootScope.log('password reset then:', response);
                // $rootScope.log('===================================== AUTH SERVICE ===============================');
                if (response && response.data){
                    callback(response.data);
                } else {
                    callback(response);
                }

            })
            .catch(function(error) {
                // $rootScope.log('===================================== AUTH SERVICE ===============================');
                $rootScope.log('password reset catch:', error);
                // $rootScope.log('===================================== AUTH SERVICE ===============================');
                if (error.data){
                    callback(error.data);
                } else {
                    callback(error);
                }

            });
    }

    function passwordResetLink(username,  callback) {
        $http.post('/api/user/password_reset', {
                user: username,
            })
            .then(function(response) {
                // $rootScope.log('===================================== AUTH SERVICE ===============================');
                $rootScope.log('password reset then:', response);
                // $rootScope.log('===================================== AUTH SERVICE ===============================');
                if (response && response.data){
                    callback(response.data);
                } else {
                    callback(response);
                }

            })
            .catch(function(error) {
                // $rootScope.log('===================================== AUTH SERVICE ===============================');
                $rootScope.log('password reset catch:', error);
                // $rootScope.log('===================================== AUTH SERVICE ===============================');
                if (error.data){
                    callback(error.data);
                } else {
                    callback(error);
                }

            });
    }

    //CHECK THIS AS OBSOLETE
    // function __loginGoogle(prompt = 'none', username, callback) {
    //     var googleError = "";
    //     service.ClearCredentials();
    //     if (typeof(gapi) == 'undefined') {
    //         googleError = "Please check your internet connection, can not initialize google login. Refresh page and try again.";
    //         if(callback) {
    //             callback(googleError, null);
    //         }
    //         return;
    //     }

    //     var localScope = {
    //         username: username,
    //         callback: callback,
    //         __loginGoogle: service.__loginGoogle
    //     };

    //     // if we are not logged in locally but we have google access token
    //     if(AuthService.myProfile && Object.keys(AuthService.myProfile).length >= 0
    //         && localScope.username && localScope.username.length > 0 && localScope.username != AuthService.currentUser) {

    //         prompt = 'select_account';
    //     }
    //     gapi.load('client:auth2', {
    //         timeout: 5000, //5sec timeout
    //         onerror: function() {
    //             // Handle timeout.
    //             if(callback) {
    //                 callback("Error occured while loading auth. Refresh page and try again.", null);
    //             }
    //             return;
    //         },
    //         ontimeout: function() {
    //             // Handle timeout.
    //             if(callback) {
    //                 callback("Please check your internet connection, can not initialize google login in 5 seconds. Refresh page and try again.", null);
    //             }
    //             return;
    //         },
    //         callback: function() {
    //             console.log ("gapi auth2 initialized");

    //             gapi.auth2.authorize({
    //                 client_id: '415410027250-32havk00d69n1au2n311c2p7aj3v8244.apps.googleusercontent.com',
    //                 scope: 'email profile openid',
    //                 login_hint: localScope.username,
    //                 response_type: 'id_token permission',
    //                 prompt: prompt
    //             }, function(response) {
    //                 if (response.error) {
    //                     // An error happened!
    //                     if (response.error == "user_logged_out"){
    //                         localScope.__loginGoogle("select_account", username, localScope.callback);
    //                         return;
    //                     }
    //                     if (response.error == "immediate_failed"){
    //                         // localScope.googleError = "No user could be automatically selected without prompting";
    //                         localScope.__loginGoogle("select_account", username, localScope.callback);
    //                         return;
    //                     }
    //                     if (response.error == "access_denied"){
    //                         if(localScope.callback) {
    //                             localScope.callback("The user denied the permission to the scopes required.", null);
    //                         }
    //                         return;
    //                     }
    //                     if (response.error == "popup_blocked_by_browser") {
    //                         if(localScope.callback) {
    //                             localScope.callback("Your browser is blocking Google Login Popup to appear. Please enable popup to show before trying again.", null);
    //                         }
    //                         return;
    //                     }
    //                     if (response.error == "popup_closed_by_user"){
    //                         if(localScope.callback) {
    //                             localScope.callback("You've closed popup for signing in before we could finish the login process!", null);
    //                         }
    //                         return;
    //                     }
    //                     console.log("Some unhandled error happened when login google:", response.error);
    //                     if(localScope.callback) {
    //                         localScope.callback("Unhandled response:" + response.error, null);
    //                     }
    //                     return;
    //                 }
    //                 else {
    //                     if(localScope.callback) {
    //                         localScope.callback(null, response);
    //                     }
    //                     return;
    //                 }

    //             });
    //         }
    //     });
    // }

    function __login(username, password, oauthToken, redirectUrl, callback) {
        if(!oauthToken && !username) {
            return callback({data: {error: 'Username is required'}}, null);
        }
        if(!oauthToken && !password) {
            return callback({data: {error: 'Password is required'}}, null);
        }
        if(!oauthToken && !__isEmailFormatValid(username)) {
            return callback({data: {error: 'Username has to be a valid e-mail address'}}, null);
        }

        $http.post('/api/v2/login', {
                email: username,
                password: password,
                oauthToken: oauthToken,
                redirectUrl: redirectUrl
            })
            .then((response) => {
                // console.log('>>> RESPONSE FROM NEW LOCAL LOGIN: ', JSON.stringify(response));
                if(response && response.data) {
                    service.SetCredentials(response.data);

                    if(response.data.redirectUrl) {
                        return $window.location.href = response.data.redirectUrl;
                    }

                    PubSub.reconnect(() => {});

                    // service.__getNotifications((callback) => {
                    //     $rootScope.pubsubInfo = callback;
                    //     PubSub.reconnect();
                    //     PubSub.subscribe({
                    //         collectionName: 'CompanyInfo',
                    //         modelId:0,
                    //         method: 'PUT'
                    //     }, (res) => {
                    //         console.log ("We have received something from pubsub:", res);
                    //         if(!$rootScope.pubsubInfo) {
                    //             $rootScope.pubsubInfo = [];
                    //         }
                    //         $rootScope.pubsubInfo.unshift(res);
                    //         $rootScope.addNotification(res);
                    //         $rootScope.$apply(() => { });
                    //         if(response.data.redirectUrl) {
                    //             return $window.location.href = response.data.redirectUrl;
                    //         }
                    //     });
                    // })

                }



                callback(null, response.data);
            })
            .catch((err) => {
                console.log('>>> ERROR FROM NEW LOCAL LOGIN: ', err);
                callback(err, null);
            });
    }

    function __register(username, password, oauthToken, invitationUid, registerData, callback) {
        $http.post('/api/v2/register', {
                email: username,
                password: password,
                oauthToken: oauthToken,
                invitationUid: invitationUid,
                registerData: registerData
            })
            .then((response) => {
                console.log('>>> RESPONSE FROM NEW REGISTER: ', response);
                if(response && response.data) {
                    service.SetCredentials(response.data);
                    if(response.data.redirectUrl) {
                        return $window.location.href = response.data.redirectUrl;
                    }
                }
                callback(null, response.data);
            })
            .catch((err) => {
                console.log('>>> ERROR FROM NEW REGISTER: ', err);
                callback(err, null);
            });
    }

    /*
     * LOGOUT ACCOUNT
     */
    function Logout(callback) {
        $http.post('/api/logout')
            .then(function(response) {
                try {
                    PubSub.unSubscribeAll();
                } catch (ex){
                }
                if (response && response.data){
                    callback(response.data);
                } else {
                    callback(response);
                }
            })
            .catch((err) => {
                console.log("We have error in logout:", err);
                callback(err);
            });
    }

    // function __joinChannels(callback) {
    //     $http.get('/api/chat/join')
    //         .then((response) => {
    //             console.log("Active channels subscribed:", response);
    //             service.activeChannels = response.data.channels;
    //             // service.activeChats = response.data.notifications
    //             // angular.forEach(service.activeChannels, (channel) => {
    //             //     service.__joinChannel(channel)
    //             // })
    //             callback();
    //         })
    //         .catch((err) => {
    //             callback()
    //         })
    // }

    function __joinChannel(channel) {
        if (service.activeChannels[channel.projectId])
        {
            console.log("Already joined");
            return;
        }

        if (!channel.projectId || !channel.channel){
            console.log("We do not have project or channel to join to!");
            return;
        }

        try {
            console.log("Join to channel:", channel)
            service.activeChannels[channel.projectId]=channel.channel;
            service.unreadMessages[channel.projectId] = 0;
            service.chatMessages[channel.projectId] = new DynamicItems(channel.projectId);
        } catch (ex) {
            console.log("error while getting dynamic items:", ex)
        }


        PubSub.subscribe({
            collectionName: channel.channel,
            modelId: channel.projectId,
            method: 'PUT'
        }, (result) => {
            // console.log("We have received from pubsub on project channel!", result);
            // if (!service.activeChats){
                // service.activeChats = [];
            // }
            if (result && result.type == 'notification_read'){
                //if we are only acknowledging just show
                $rootScope.$emit('chatmessage_read:emit', result); // $rootScope.$on
            } else {
                $rootScope.$emit('chatmessage:emit', result); // $rootScope.$on

                // service.activeChats.unshift(result);
                if (result.projectId && !service.visibleChats[result.projectId]){
                    $rootScope.addNotification(result);
                    $rootScope.$apply(() => { });
                } else {
                    if (result.projectId){
                        var notification = angular.copy(result);
                        notification.onlyDesktopNotification = true;
                        $rootScope.addNotification(notification);
                        $rootScope.$apply(() => { });
                    }
                }
            }
        })
    }

    function __getProjectMessages(data, callback) {
        var projectId = data.projectId;
        var firstId = data.firstId;
        var pagesize = data.pagesize;
        var onlycount = data.onlycount;

        if ($rootScope.checkMethod('GET__/api/chat/messages') < 0) {
            return callback(null);
        }

        $http.get('/api/chat/messages', {params: {projectId: projectId, firstId: firstId, offset: data.offset, pagesize: pagesize, onlycount: onlycount}})
            .then((response) => {
                if (response.data){
                    callback(response.data);
                } else {
                    callback([]);
                }
            })
            .catch((err) => {
                $rootScope.log("Error while getting messages:", err);
                callback([])
            })
    }


    function __getProjectMessagesPromise(data) {
        var projectId = data.projectId;
        var firstId = data.firstId;
        var pagesize = data.pagesize;
        var onlycount = data.onlycount;

        if ($rootScope.checkMethod('GET__/api/chat/messages') < 0) {
            var deferred = $q.defer();
            // return callback(null);
            deferred.reject();
            return deferred.promise
        }

        return $http.get('/api/chat/messages', {params: {projectId: projectId, firstId: firstId, offset: data.offset, pagesize: pagesize, onlycount: onlycount}})

    }

    function __sendMessage(data, callback) {
        $http.post('/api/chat/send', data)
            .then((response) => {
                // console.log("Sent message :", response);
                callback();
            })
            .catch((err) => {
                callback()
            })
    }
    /*
     * SET USER CREDENTIALS
     */
    function SetCredentials(user_object) {
        // console.log("User object in SetCredentials is:", user_object)
        if(!user_object.currentUser && !user_object.availableMethods) {
            return;
        }
        if(!$rootScope.globals) {
            $rootScope.globals = {};
        }
        $rootScope.globals.currentUser = user_object.currentUser;
        $rootScope.globals.availableMethods = user_object.availableMethods;
        service.currentUser = user_object.currentUser.username;
        service.myProfile = user_object.currentUser;
        // $http.defaults.headers.common['Authorization'] = user_object.currentUser.token;
        $window.localStorage.setItem('globals', JSON.stringify($rootScope.globals));
        // set authentication token in cookie
        // $cookies.put('fb_token', user_object.currentUser.token, {path: '/'});
        console.log("Set cookie to browser storage::", user_object.currentUser.token)
    }

    /*
     * CLEAR USER CREDENTIALS
     */
    function ClearCredentials() {
        $rootScope.log("Clearing credentials");
        //logout from backend
        service.Logout((result) => {
        //logout from frontend
        service.ClearFrontCredentials().then(() => { })

        });
    }

    /*
     * CLEAR FRONT CREDENTIALS
     */
    function ClearFrontCredentials() {
        var deferred = $q.defer();
        $rootScope.log("Clearing Frontend credentials");
        try {
            PubSub.unSubscribeAll();
        } catch (ex){

        }
        $rootScope.globals = {};
        service.currentUser = "";
        service.myProfile = {};
        $window.localStorage.removeItem('globals');
        // remove token from cookiestore
        $cookies.remove('fb_token');
        // remove stripe
        $cookies.remove('__stripe_sid');
        $cookies.remove('__stripe_mid');
        // remove socket-io token
        PubSub.disconnect();
        $cookies.remove('io');
        // $http.defaults.headers.common.Authorization = '';

        // var cookies = $cookies.getAll();
        // console.log('>> LEFT OVER COOKIES: ', JSON.stringify(cookies))

        deferred.resolve();
        return deferred.promise;
    }

    /*
     * CHECK IF USER EXISTS
     *
     * @param string key
     * @param string value
     */
    function UserExists(key, value, callback) {

        // $rootScope.log('==> DEBUG: AuthService(): UserExists():', key, value);

        $http.post('/api/user/exists', {
                key: key,
                value: value
            })
            .then(function(response) {
                // $rootScope.log('==> DEBUG: AuthService(): UserExists(): RESPONSE', response.data);
                if (response && response.data){
                    callback(response.data);
                } else {
                    callback(response);
                }

            });
    }

    /*
     * CHECK IF USER EXISTS
     *
     * @param string key
     * @param string value
     */
    function __userExistsPromise(username) {

        // $rootScope.log('==> DEBUG: AuthService(): UserExists():', key, value);

        return $http.post('/api/user/exists', {
                key: 'username',
                value: username
            });
    }

    /*
     * __updateMyProfile()
     *
     * @param obj user
     * @return callback
     */
    function __updateMyProfile(user, callback) {
        $rootScope.log('==> USER IS: ', user);
        $http.post('/api/myprofile/edit', {
                user: user
            })
            .then(function(response) {
                if (response && response.data){
                    callback(response.data);
                } else {
                    callback(response);
                }

            });
    }

    /*
     * __reSendConfirmationEmail()
     *
     * @return callback
     */
    function __reSendConfirmationEmail(uuid, callback) {
        $http.post('/api/myprofile/resend_confirmation_email', {uuid: uuid})
            .then((response) => {
                if (response && response.data){
                    callback(response.data);
                } else {
                    callback(response);
                }
            })
    }

    function __readNotification(notification_id, callback){
        if ($rootScope.checkMethod('POST__/api/public/notifications/read') < 0) {
            return callback(null);
        }

        $http.post('/api/public/notifications/read', {notification_id: notification_id})
            .then(function (response) {
                if (response && response.data){
                    callback(response.data);
                } else {
                    callback(response);
                }

            })
            .catch((err) => {
                // console.log("Error: /api/public/notifications/read:", err);
                $rootScope.addNotification({
                    type: 'error',
                    message: err
                });
                callback(err)
            });
    }

    function __readMessage(notification_id, callback){
        if ($rootScope.checkMethod('POST__/api/public/notifications/read_message') < 0) {
            return callback(null);
        }

        $http.post('/api/public/notifications/read_message', {notification_id: notification_id})
            .then(function (response) {
                if (response && response.data){
                    callback(response.data);
                } else {
                    callback(response);
                }

            })
            .catch((err) => {
                // console.log("Error: /api/public/notifications/read:", err);
                $rootScope.addNotification({
                    type: 'error',
                    message: err
                });
                callback(err)
            });
    }

    function __readAllNotification(notification_id, callback){
        if ($rootScope.checkMethod('POST__/api/public/notifications/readall') < 0) {
            return callback(null);
        }

        $http.post('/api/public/notifications/readall')
            .then(function (response) {
                if (response && response.data){
                    callback(response.data);
                } else {
                    callback(response);
                }
            })
            .catch((err) => {
                // console.log("Error: /api/public/notifications/read:", err);
                $rootScope.addNotification({
                    type: 'error',
                    message: err
                });
                callback(err)
            });
    }

    function __getNotifications(callback) {
        if ($rootScope.checkMethod('GET__/api/public/notifications/all') < 0) {
            return callback(null);
        }

        $http.get('/api/public/notifications/all')
            .then(function (response) {
                if (response && response.data){
                    callback(response.data);
                } else {
                    callback(response);
                }
            })
            .catch((err) => {
                // console.log("Error: /api/public/notifications/all:", err);
                $rootScope.addNotification({
                    type: 'error',
                    message: err
                });
                callback(err)
            });
    }

    function __getCompanyFromAbn(abn, callback){
        var ourGuid = 'd1116a34-8c7c-40c7-af33-704acb723c37';
        var endpoint = 'https://abr.business.gov.au/json/AbnDetails.aspx?abn=' + abn + '&guid=' + ourGuid;
        // var endpoint = 'https://abr.business.gov.au/json/AbnDetails.aspx?abn=' + abn + '&callback=callback&guid=' + ourGuid;
        var promise;
        var trustedUrl = $sce.trustAsResourceUrl(endpoint);

        promise = $http.jsonp(trustedUrl, {jsonpCallbackParam: 'callback'});

        promise.then((response) => {
            if (response && response.data){
                callback(response.data);
            } else {
                callback(response);
            }

        })
        .catch(err => {
            console.log("Error getting ABN:", err);
        })
    }

    function __logevent(event, data){
        var sendData = data;
        if (Array.isArray(data) || typeof data == 'object'){
            // NOTE: this may break the app because stringify will fail for circular data or large objects
            try {
                sendData = JSON.stringify(data);
            }
            catch (err) {
                sendData = err;
            }
        }
        $http.post('/api/log/event', {event: event, eventdata: sendData});
    }

    /**
     * GET MY DASHBOARD WIDGETS
     */
    function __getMyDashboardWidgets(callback) {
        if ($rootScope.checkMethod('GET__/api/dashboard/widgets/get') < 0) {
            return callback(null);
        }
        $http.get('/api/dashboard/widgets/get')
            .then((response) => {
                if (response && response.data){
                    callback(response.data);
                } else {
                    callback(response);
                }

            })
            .catch((err) => {
                $rootScope.addNotification({
                    type: 'error',
                    message: err
                });
                callback(err);
            });
    }

    /**
     * GET MY AVAILABLE WIDGETS
     *
     * @param {function} callback callback fn
     */
    function __getMyAvailableWidgets(callback) {
        if ($rootScope.checkMethod('GET__/api/dashboard/widgets/get_available') < 0) {
            return callback(null);
        }
        $http.get('/api/dashboard/widgets/get_available')
            .then((response) => {
                if (response && response.data){
                    callback(response.data);
                } else {
                    callback(response);
                }

            })
            .catch((err) => {
                $rootScope.addNotification({
                    type: 'error',
                    message: err
                });
                callback(err);
            });
    }

    /**
     * SUBMIT FEEDBACK
     */
    function __submitFeedback(data, callback) {
        if ($rootScope.checkMethod('POST__/api/submit_feedback') < 0) {
            return callback(null);
        }

        $http.post('/api/submit_feedback', data)
            .then((response) => {
                if (response && response.data){
                    callback(null, response.data);
                } else {
                    callback("Feedback submit error", null);
                }

                callback(null, response.data);
            })
            .catch((err) => {
                $rootScope.addNotification({
                    type: 'error',
                    message: err
                });
                callback(err, null)
            });
    }

    /**
     * CHECK IF ABN IS IN USE
     * @param {data} object
     * @param {function} callback callback function
     */
    function __checkIfAbnAlreadyInUse(data, callback) {
        var params = {
            params: data
        };
        $http.get('/api/users/company_number_used', params)
            .then((response) => {
                callback(null, response.data);
            })
            .catch((err) => {
                $rootScope.addNotification({
                    type: 'error',
                    message: err
                });
                callback(err, null);
            });
    }

    /**
     * GET MY USER PAYLOAD
     * @param {bool} force boolean whether to force payload reinit
     * @param {function} callback callback function
     */
    function __getMyUserPayload(force = false, callback) {
        if ($rootScope.checkMethod('GET__/api/v2/user/get_payload') < 0) {
            return callback(null);
        }
        var params = {
            params: {
                force: force
            }
        };
        $http.get('/api/v2/user/get_payload', params)
            .then((response) => {
                if(response && response.data) {
                    service.SetCredentials(response.data);
                    service.__initializeGlobals();
                    callback(null, response.data);
                }
            })
            .catch((err) => {
                $rootScope.addNotification({
                    type: 'error',
                    message: err
                });
                callback(err, null);
            });
    }

    /**
     * DECLINE COMPANY INVITATION
     */
    function __declineInvitation(data, callback) {
        $http.post('/api/v2/invitation/company/decline', data)
            .then((response) => {
                callback(null, response.data);
            })
            .catch((err) => {
                $rootScope.log('====> DEBUG: AdminService::__declineInvitation():: error: ', err);
                callback(err, null);
            });
    }

    /**
     * ACCEPT COMPANY INVITATION
     */
    function __acceptInvitation(data, callback) {
        $http.post('/api/v2/invitation/company/accept', data)
            .then((response) => {
                callback(null, response.data);
            })
            .catch((err) => {
                $rootScope.log('====> DEBUG: AdminService::__acceptInvitation():: error: ', err);
                callback(err, null);
            });
    }

    /**
     * DECLINE COMPANY INVITATION
     */
    function __validateAccount(data, callback) {
        $http.post('/api/v2/validate_account', data)
            .then((response) => {
                callback(null, response.data);
            })
            .catch((err) => {
                $rootScope.log('====> DEBUG: AdminService::__validateAccount():: error: ', err);
                callback(err.data.error, null);
            });
    }

    // VALIDATE EMAIL FORMAT
    function __isEmailFormatValid(email) {
        if(!email) return false;
        var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    }


}

'use strict';

angular.module('FSS')
  .factory('SearchService', SearchService);

SearchService.$inject = ['$http', '$rootScope', '$timeout', '$q', 'ConfigService'];

function SearchService($http, $rootScope, $timeout, $q, ConfigService) {

  var service = {};

  service.getAllBuilders  = getAllBuilders;
  service.getBuilderBy    = getBuilderBy;
  service.getSelectedBuilders  = getSelectedBuilders;
  service.getAllCategories  = getAllCategories;
  service.getLocationForAddress = getLocationForAddress;
  service.__getPostCodesList = __getPostCodesList;
  return service;

  /*
   * getSelected
   *
   * @return callback
   */
  function getSelectedBuilders(id, callback) {
    // get selected builder from backend
      $http.get('/api/public/builders/get', { params: {id: id}})
      .then(function(response) {
        callback(response.data);
      });
  }
  /*
   * getAllBuilders
   *
   * @return callback
   */
  function getAllBuilders(data, callback) {
    // get all builders from backend
    var config = {
      params: {
          page: (data && data.page) ? data.page : null,
          offset: (data && data.offset) ? data.offset : null,
          search: (data && data.search) ? data.search : null,
          category: (data && data.category) ? data.category : null,
          projectId: (data && data.projectId) ? data.projectId : null,
          locationBased: (data && data.locationBased) ? data.locationBased : null,
          eligibleOnly: (data && data.eligibleOnly) ? data.eligibleOnly : null,
        }
    };

    $http.get('/api/public/builders/all', config)
      .then(function(response) {
        callback(response.data);
      })

    // SIMULATION
    // var builders  = [];
    // var tmp       = {};
    // for (var i=1; i<11; i++) {
    //   tmp = {
    //     name: 'The Builder Name - ' + i,
    //     image: '/assets/front/img/sample-product.png',
    //     address: 'Australia Fair, Qld 421' + i,
    //     tags: ['furniture','painting'],
    //     excerpt: 'Affordable Boat Covers Gold Coast is a leader in the boat covers, marine trimming and upholstery industry, with over 15 years experience fabricating products that can withstand the harsh Australian weather, UV Rays and salt water. Our experience in the marine, automotive and canvas manufacturing industries ensures that you will receive only the finest workmanship.',
    //     id: i,
    //   };
    //   builders.push(tmp);
    // }
    // // $rootScope.log('==> DEBUG: SearchService::getAllBuilders():', builders);
    // callback(builders);
  }

  /*
   * Show all available categories
   *
   * @return callback
   */
  function getAllCategories(callback) {
    // get all Categories from backend
    // var config = {
    //   params: {
    //       page: (data && data.page) ? data.page : null,
    //       offset: (data && data.offset) ? data.offset : null,
    //       search: (data && data.search) ? data.search : null,
    //     }
    // };
    $http.get('/api/public/categories/all')
      .then(function(response) {
        callback(response.data);
      })
  }

  function __getPostCodesList(data, callback) {
    if ($rootScope.checkMethod('GET__/api/public/postcodes/all') < 0) {
        return callback(null);
    }
    var config = {
        params: {
            ids: (data && data.ids) ? JSON.stringify(data.ids) : null
        }
    };
    $http.get('/api/public/postcodes/all', config)
        .then((response) => {
            callback(response.data);
        })
        .catch((err) => {
            console.log('ERROR:SearchService::__getPostCodesList(err):', err);
        });
  }

  /*
   * getBuilderBy
   *
   * @param string key
   * @param string value
   * @return callback
   */
  function getBuilderBy(key, value, callback) {

    $http.post('/api/builder/get', { key: key, value: value })
       .then(function (response) {
          $rootScope.log('==> DEBUG: SearchService(): getBuilderBy(): RESPONSE', response);
          callback(response.data);
       });

  }

  function getLocationForAddress(address, field) {
    var promise;
    //   $rootScope.log("Regresh address...")
    if (!address) {
        promise = $q.when({
            data: {
                results: []
            }
        });
    } else {
        var params = {
            address: address,
            sensor: false
        };
        var endpoint = '/api/v2/public/geocode';
        promise = $http.post(endpoint, params);
    }
    return promise;
}

} // END SearchService()
'use strict';

angular
    .module('FSS')
    .factory('CategoryService', CategoryService);

CategoryService.$inject = ['$http', '$rootScope', '$timeout', '$q'];

function CategoryService($http, $rootScope, $timeout, $q) {

    var service = {};

    // SUPPLIER CATEGORIES STUFF
    service.__getSupplierItemParentCategories = __getSupplierItemParentCategories;
    service.__getSupplierItemCategories = __getSupplierItemCategories;
    service.__createSupplierItemCategory = __createSupplierItemCategory;
    service.__editSupplierItemCategory = __editSupplierItemCategory;
    service.__getSupplierItemCategoriesBy = __getSupplierItemCategoriesBy;
    service.__deleteSupplierItemCategory = __deleteSupplierItemCategory;
    service.__getMySelectedCategories = __getMySelectedCategories;

    return service;


    // DELETE SUPPLIER CATEGORY
    function __deleteSupplierItemCategory(id, callback) {
        if ($rootScope.checkMethod('POST__/api/supplier-item-categories/remove') < 0) {
            return callback(null);
        }
        $http.post('/api/supplier-item-categories/remove', {
                id: id
            })
            .then(function(response) {
                callback(response.data);
            });
    }

    /*
     * Get all parent Supplier Categories
     *
     * __getSupplierItemParentCategories
     *
     * @param obj data
     * @return callback
     */
    function __getSupplierItemParentCategories(data, parent = true, callback) {
        if ($rootScope.checkMethod('GET__/api/supplier-item-categories/all') < 0) {
            return callback(null);
        }

        var config = {
            params: {
                page: (data && data.page) ? data.page : null,
                offset: (data && data.offset) ? data.offset : null,
                search: (data && data.search) ? data.search : null,
                parent: parent,
                type: (data && data.type) ? data.type : null,
                maintenance: (data && data.maintenance) ? data.maintenance : null
            }
        };

        $http.get('/api/supplier-item-categories/all', config)
            .then(function(response) {
                // $rootScope.log('DEBUG: CategoryService::__getSupplierItemParentCategories():', response);
                callback(response.data);
            });
    }

    /*
     * Get all available supplier items for its CRUD page
     *
     * __getSupplierItemCategories
     *
     * @param obj data
     * @return callback
     */
    function __getSupplierItemCategories(data, callback) {
        if ($rootScope.checkMethod('GET__/api/supplier-item-categories/all') < 0) {
            return callback(null);
        }

        var config = {
            params: {
                page: (data && data.page) ? data.page : null,
                offset: (data && data.offset) ? data.offset : null,
                search: (data && data.search) ? data.search : null
            }
        };

        $http.get('/api/supplier-item-categories/all', config)
            .then(function(response) {
                $rootScope.log('DEBUG: CategoryService::__getSupplierItemCategories():', response);
                callback(response.data);
            });
    }

    /*
     * __createSupplierItemCategory()
     *
     * @param obj data
     * @return callback
     */
    function __createSupplierItemCategory(data, callback) {
        if ($rootScope.checkMethod('POST__/api/supplier-item-category/create') < 0) {
            return callback(null);
        }
        $http.post('/api/supplier-item-category/create', {
                category: data.category,
            })
            .then((response) => {
                callback(response.data);
            })
            .catch((err) => {
                callback(err);
            });
    }

    /*
     * __editSupplierItemCategory()
     *
     * @param obj data
     * @return callback
     */
    function __editSupplierItemCategory(data, callback) {
        if ($rootScope.checkMethod('POST__/api/supplier-item-category/edit') < 0) {
            return callback(null);
        }
        $http.post('/api/supplier-item-category/edit', {
                category: data.category,
            })
            .then(function(response) {
                callback(response.data);
            });
    }


    /*
     * __getSupplierItemCategoriesBy
     *
     * @param string key
     * @param string value
     * @return callback
     */
    function __getSupplierItemCategoriesBy(key, value, callback) {
        if ($rootScope.checkMethod('POST__/api/supplier-item-categories/get') < 0) {
            return callback(null);
        }
        $http.post('/api/supplier-item-categories/get', {
                key: key,
                value: value
            })
            .then(function(response) {
                $rootScope.log('==> DEBUG: AdminService(): __getSupplierItemCategoriesBy(): RESPONSE', response);
                callback(response.data);
            });
    }

    /**
     * GET LOGGED IN USER CATEGORIES
     */
    function __getMySelectedCategories(data, callback) {
        if ($rootScope.checkMethod('GET__/api/supplier-item-categories/personal') < 0) {
            return callback(null);
        }

        var config = {
            params: {
                search: (data && data.search) ? data.search : null
            }
        };

        $http.get('/api/supplier-item-categories/personal', config)
            .then((response) => {
                return callback(response.data);
            })
            .catch((err) => {
                console.log('>> CategoryService::__getMySelectedCategories(err): ', err);
                return callback(null);
            })
    }

} // END CategoryService()
'use strict';

angular.module('FSS')
  .factory('FrontendProjectService', FrontendProjectService);

FrontendProjectService.$inject = ['$http', '$rootScope', '$timeout', '$q'];

function FrontendProjectService($http, $rootScope, $timeout, $q) {

  var service = {};

  service.getAllBuilders        = getAllBuilders;
  service.getBuilderBy          = getBuilderBy;
  service.getSelectedBuilders   = getSelectedBuilders;
  service.__getPostcodes        = __getPostcodes;
  service.__getPostcodeStates   = __getPostcodeStates;
  service.__getStateSuburbs         = __getStateSuburbs;
  service.__getAreas            = __getAreas;
  service.__createProject       = __createProject;
  service.__getSupplierItemsByAreaAndType = __getSupplierItemsByAreaAndType;
  service.__submitHowDidYouHearAboutUs = __submitHowDidYouHearAboutUs;
  return service;

    /**
     * Add unique key to every created area
     *
     * @param {object} mproject Existing project
     */
    function __updateZonesUnique(mproject) {
        if (mproject.zones) {
            angular.forEach(mproject.zones, (zones) => {
                if (!zones.unique) {
                    zones.unique = __generateUUID();
                }
            })
        }
    }
    function __generateUUID() {
        var d = new Date().getTime();
        var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = (d + Math.random() * 16) % 16 | 0;
            d = Math.floor(d / 16);
            return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16);
        });
        return uuid;
    };

  /*
   * getSelected
   *
   * @return callback
   */
  function getSelectedBuilders(sel, callback) {
    // get all builders from backend
    $http.post('/api/builders/getselected', {choosen_categories: sel})
      .then(function(response) {
        callback(response.data);
      });
  }
  /*
   * getAllBuilders
   *
   * @return callback
   */
  function getAllBuilders(callback) {
    // get all builders from backend
    $http.post('/api/builders/get')
      .then(function(response) {
        callback(response.data);
      })

  }

  function getAllBuilders(callback) {
    // get all builders from backend
    $http.post('/api/builders/get')
      .then(function(response) {
        callback(response.data);
      })

  }

  function __createProject(project, callback) {
      __updateZonesUnique(project);
    //Create Backend Project
    $http.post('/api/public/project/create', {projectDetails: project})
      .then(function(response) {
          if (response.error){
            callback(response);
          } else {
            callback(response.data);
          }

      })
  }
  function  __getAreas(search, callback) {
    // if ($rootScope.checkMethod('GET__/api/areas/all') < 0) {
    //     return callback(null);
    // }

    var config = {
        params: {
            search: search
        }
    };


    $http.get('/api/public/areas/get', config)
        .then(function(response) {
            //$rootScope.log('DEBUG: CategoryService:', JSON.stringify(response,null,2));
            callback(response.data);
        });
  }

  function  __getPostcodes(search, callback) {
    // if ($rootScope.checkMethod('GET__/api/areas/all') < 0) {
    //     return callback(null);
    // }

    var config = {
        params: {
            search: search
        }
    };

    if (!search){
        return callback([]);
    };

    $http.get('/api/postcode/get', config)
        .then(function(response) {
            //$rootScope.log('DEBUG: CategoryService:', JSON.stringify(response,null,2));
            callback(response.data);
        });
  }

  function  __getPostcodeStates(callback) {
    $http.get('/api/postcode/get_states')
        .then((response) => {
            if(response && response.data) {
                callback(response.data);
            } else {
                callback([]);
            }
        });
  }

  /**
   * GET SUBURBS FOR SPECIFIC STATE / SEARCH SUBURBS
   *
   * @param {object} data search data object
   * @param {function} callback callback function
   */
  function __getStateSuburbs(data, callback) {
    $http.get('/api/postcode/state/get', {params: data})
        .then((response) => {
            if(response && response.data) {
                callback(response.data);
            } else {
                callback([]);
            }
        })
        .catch((err) => {
            callback([]);
        });
  }

  /*
   * getBuilderBy
   *
   * @param string key
   * @param string value
   * @return callback
   */
  function getBuilderBy(key, value, callback) {

    $http.post('/api/builder/get', { key: key, value: value })
       .then(function (response) {
          $rootScope.log('==> DEBUG: FrontendProjectService(): getBuilderBy(): RESPONSE', response);
          callback(response.data);
       });

  }

    /*
       * __getSupplierItemsByAreaAndType
       *
       * @param obj data
       * @param string type
       * @return callback
       */
    function __getSupplierItemsByAreaAndType(data, callback) {
        if ($rootScope.checkMethod('POST__/api/public/supplier-items/getByAreaType') < 0) {
            return callback(null);
        }
        // $rootScope.log("WE ARE GOING TO GET THIS SUP ITEMS:", data);
        $http.post('/api/public/supplier-items/getByAreaType', { data: data })
            .then(function (response) {
                $rootScope.log('==> DEBUG: FrontendProjectService(): __getSupplierItemsByAreaAndType(): RESPONSE', response);
                // response.data.item_values = JSON.parse(response.data.item_values);
                callback(response.data);
            });
    }

    /*
    * __submitHowDidYouHearAboutUs
    *
    * @param obj data
    * @param string type
    * @return callback
    */
    function __submitHowDidYouHearAboutUs(data, callback) {
        $http.post('/api/v2/public/where_did_you_hear_about_us', data)
            .then((response) => {
                $rootScope.log('==> DEBUG: FrontendProjectService(): __submitHowDidYouHearAboutUs(): RESPONSE', response);
                callback(response.data);
            });
    }


} // END FrontendProjectService()
'use strict';

/**
 * ------------ ERROR CATCHER -------------------
 */
angular.module('ErrorCatcher', [])
    .factory('$exceptionHandler', ['ConfigService', function(ConfigService) {
        return function errorCatcherHandler(exception, cause) {
            console.error(exception.stack);
            // log to sentry when in production env only
            if(Sentry && ConfigService.APP_ENV == 'PRODUCTION') {
                Sentry.captureException(exception);
                // Sentry.showReportDialog({ eventId: Sentry.lastEventId() });
            }
        };
    }]);

/**
 * ------------ CONFIG SERVICE ------------------
 */

angular
    .module('FSS')
    .factory('ConfigService', ConfigService);

// ConfigService.$inject = ['$http', '$rootScope', '$timeout', '$q'];

function ConfigService() {

    // TODO: load these from backend if needed
    var _prod_domains = [
        'fitoutbuilders.com',
        'www.fitoutbuilders.com',
        'fitoutselect.com.au',
        'www.fitoutselect.com.au',
        'fitoutbuilder.com',
        'www.fitoutbuilder.com',
        'www.fitoutbuilder.com.au',
        'fitoutbuilder.com.au',
        'fitoutbuilders.com.au',
        'www.fitoutbuilders.com.au'
    ];
    var _env = 'DEVELOPMENT';
    if(_prod_domains.indexOf(window.location.hostname) > -1) {
        _env = 'PRODUCTION';
    }

    var service = {
        // export current environment
        APP_ENV: _env,
        // production opts here
        PRODUCTION: {
            gapi_maps: 'AIzaSyA04jC9d27EHNhL5H07axUZ1DHZMY9hmIg',
            gclient_id: '663590159786-4s66h149n13k48j3kndjjjtk2a9m39j2.apps.googleusercontent.com',
            sentry: 'https://a09c480192554b109a0814b76c5fc3f1@err.ipocs.net/5',
            stripe: 'pk_live_IeAEI3CSwebxdPJXYgMrNCdx00AH5zg82H',
        },
        // development opts here
        DEVELOPMENT: {
            gapi_maps: 'AIzaSyCPT3j9meTvIZjLO6DBnGtn-EHsrUq30m0',
            gclient_id: '415410027250-32havk00d69n1au2n311c2p7aj3v8244.apps.googleusercontent.com',
            sentry: 'https://9d4b32a780724105a87e361fc2f9db23@err.ipocs.net/3',
            stripe: 'pk_test_0WUmFV4hORV3lHRYV5cwhaM200HGw0gTeD'
        }
    };

    // expose wanted methods
    service.__setupSentry = __setupSentry;
    service.__setupGoogleMaps = __setupGoogleMaps;
    service.__getEnvSettings = __getEnvSettings;

    return service;


    /**
     * Setup Sentry
     */
    function __setupSentry() {
        if(!_env) {
            return;
        }
        // handle sentry
        if(service[_env] && service[_env].sentry) {
            Sentry.init({
                dsn: service[_env].sentry,
                debug: _env == 'DEVELOPMENT' ? true : false,
                // beforeSend(event) {
                //     // Check if it is an exception, if so, show the report dialog
                //     if (event.exception) {
                //         Sentry.showReportDialog();
                //     }
                //     return event;
                // }
            });
        }
    }

    function __getEnvSettings() {
        if(!_env) {
            $rootScope.log('>> NO APP_ENV SETUP: ', _env);
            return;
        }
        if(service[_env]) {
            return service[_env];
        } else {
            return;
        }
    }

    /**
     * Setup Google Maps API
     * @param {boolean} returnkey return gapi key if true, otherwise inject <script> tag
     */
    function __setupGoogleMaps(returnkey = true) {
        if(!_env) {
            return;
        }
        // handle gapi
        if(service[_env] && service[_env].gapi_maps) {
            return service[_env].gapi_maps;
        }
    }



}